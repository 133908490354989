export default {
    methods: {
        getSemenceShutters(props) {
            return {
                'liste-stock': {
                    name: 'liste-stock',
                    title: this.$t('monte.stock_semence_local'),
                    cmpPath: 'components/Monte/StockLocalList',
                    props
                },
                'details-stallion': {
                    name: 'details-stallion',
                    title: this.$t('monte.stock_semence.details_stallion'),
                    cmpPath: 'components/Monte/StockStallionDetails',
                    props
                },
                'details-stallion-distant': {
                    name: 'details-stallion',
                    title: this.$t('monte.stock_semence_distant'),
                    cmpPath: 'components/Monte/StockStallionDistant',
                    props
                },
                'ajout-stock-stallion': {
                    name: 'ajout-stock-stallion',
                    title: this.$t('monte.stock_semence.add_stock'),
                    cmpPath: 'components/Monte/AjoutStockStallion',
                    props,
                    onOk: (params) => {
                        if(!params.fiche) {
                            this.setupListeStock()
                        }
                        this.setupStallionDetails(params.horse, params.tiers, params.fiche)
                    },
                },
                'import-stock': {
                    name: 'import-stock',
                    title: this.$t('monte.stock_semence.importation_stock'),
                    cmpPath: 'components/Monte/ImportStock',
                    onOk: () => {
                    	this.setupListeStock()
                    },
                },
                'sortie-stock': {
                    name: 'import-stock',
                    title: this.$t('monte.stock_semence.sortie_stock'),
                    cmpPath: 'components/Monte/SortieStock',
                    props,
                    onOk: () => {
                        this.setupListeStock()
                    },
                },
                'plan-cuve': {
                    name: 'plan-cuve',
                    title: this.$t('monte.stock_semence.plan_cuve'),
                    cmpPath: 'components/Monte/ListePlanCuve',
                    props,
                    onOk: () => {
                        this.setupListeStock()
                    },
                }
            }
        },

        setupListeStock() {
            const shutters = this.getSemenceShutters()
            this.shutterPanel().close(shutters['liste-stock'])
            this.shutterPanel().open(shutters['liste-stock'])
        },

        setupStallionDetails(horse, tiers, fiche) {
            const shutters = this.getSemenceShutters({horse, tiers, fiche})
            this.shutterPanel().close(shutters['details-stallion'])
            this.shutterPanel().open(shutters['details-stallion'])
        },

        setupStallionDetailsDistant(horse) {
            const shutters = this.getSemenceShutters({horse})
            this.shutterPanel().close(shutters['details-stallion-distant'])
            this.shutterPanel().open(shutters['details-stallion-distant'])
        },

        setupAjoutStockStallion(horse, tiers, fiche, stock = null) {
            const shutters = this.getSemenceShutters({horse, tiers, fiche, stock})
            this.shutterPanel().open(shutters['ajout-stock-stallion'])
        },

        setUpImportStock() {
            const shutters = this.getSemenceShutters()
            this.shutterPanel().open(shutters['import-stock'])
        },

        setUpSortieStock(horses) {
            const shutters = this.getSemenceShutters({horses})
            this.shutterPanel().open(shutters['sortie-stock'])
        },

        setUpPlanCuve() {
            const shutters = this.getSemenceShutters()
            this.shutterPanel().open(shutters['plan-cuve'])
        }
    }
}
