<template>
	<div>
    	<shutter-panel ref="shutterPanel" />
    </div>
</template>

<script type="text/javascript">
import Shutter from "@/mixins/Shutter.js"
import ShutterSemence from "@/mixins/shutters-manager/Semence.js"

export default {
	name: 'StallionStockDistant',
	mixins: [Shutter, ShutterSemence],
	props: ['horse'],
	data () {
		return {
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.setupStallionDetailsDistant(this.horse)
		},
	},
}

</script>